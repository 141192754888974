var vmetro = vmetro || {};
vmetro.preloader = {

  preloadingVisual: function (loadedPersent) {
    if(loadedPersent > 100) {loadedPersent = 100}
    if(loadedPersent < 0) {loadedPersent = 0}
    var path = $('svg path').last(),
        mrDasharray = path.css('stroke-dasharray')
    visualPersent = parseInt(mrDasharray) / 100,
      defaultPersent = parseInt(mrDasharray);

    path.css({
      'stroke-dashoffset': defaultPersent + Math.round(loadedPersent * visualPersent)
    });
  },

  init: function () {
    var t = this,
        readyPersent = 0,
        extraPreload = [
          "images/elems-manual-1.png", 
          "images/elems-manual-2.png", 
          "images/sprite_01.png", 
          "images/sprite_02.png",
          "images/bg-manual-1.jpg",
          "images/bg-manual-2.jpg",
          "images/bg-manual-3.jpg"
        ],
        imagesToPreload = extraPreload.length,
        imgPersent = Math.ceil(100 / imagesToPreload);
    
    for (var preloadCof = 0; preloadCof < imagesToPreload; preloadCof++) 
    {  
      var preImage = new Image();

      preImage.src = extraPreload[preloadCof];
      preImage.onload = function () {
        readyPersent = readyPersent + imgPersent;
        t.preloadingVisual(readyPersent);

        if(readyPersent >= 100) {
          setTimeout(function () {
            $('.preloader').addClass('loaded');

            setTimeout(function () {
              $('.preloader').remove();
            }, 2100);

            $('.blue-spot.nivea').show();
          }, 2000);
        }
      } // onload
    } // for
  }
};

vmetro.gameStats = {
  score: 0,
  lifes: true,
  windowHeight: $(window).height(),
  onAir: false,
  highScore: 0,
  userId: '',
  interval: 1 * 1000,
  intervalPixels: 1 * 1000,
  currentStackIndex: 0,
  intervalSpeed: 30,
  speedFaster: 200,
  stackHeight: 200, // height of the block in pixels
  timer: null
};

function Subject (value) {
  this.image = value.image;
  this.position = { top: $(window).height() + 200, left: parseInt(value.left) };
  this.speed = parseInt(value.speed);
  this.effect = value.effect;
  this.dimensions = { width: null, height: null };
  this.RefToDOM = null;
  this.isOnBoard = false;
  this.delay = parseInt(value.delay);
  this.alivePeriod = 10 * 1000;
  this.points = parseInt(value.points);
  this.currentPeriod = 0;
  this.title = value.title;
  this.angle = parseInt(value.angle) + 1;
  this.cssClassName = value.cssClassName;

  var this__ = this;

  this.addToScene = function() {
    game.container.append(this__.RefToDOM);
    // this__.RefToDOM.addClass('moving');
    setTimeout(function () {
      var max = 30, min = -30;
      this__.angle =  this__.angle + parseInt(Math.floor(Math.random() * (max - min + 1)) + min);
      this__.RefToDOM.css({
          'transform': 'translate(' + this__.position.left + 'px, -200px) rotate(' + this__.angle + 'deg)',
          'transition': 'all ' + this__.speed / game.gravitation + 's linear'
      });
    },100);
    this__.isOnBoard = true;
  };

  this.removeFromScene = function() {
    this__.isOnBoard = false;
    this__.currentPeriod = 0;
    this__.top = 0;
    // Prevent for loop add to scene
    this__.delay = 800 * 1000;

    // this__.delay = game.timer;
    this__.RefToDOM.remove();

    // Restart the game loop with the same subjects set
    if ((game.numberOfSubjects - 14) == this__.id) {
      // Finish the game when the subjects finish
      // game.finish();
      clearInterval(game.gameInterval);
      game.init(game.rawData);
      if (game.gravitation < 4) {
        game.gravitation = game.gravitation * 1.5;
        if (game.gravitation > 4) { game.gravitation = 4; }
      }
      game.start();
    }
  };

  this.move = function() {
    this__.position.top = this__.position.top + this__.speed * game.gravitation;
    this__.RefToDOM.css({
      'transform': 'translate(' + this__.position.left + 'px, 0px)'
    });
  };
};

function Game() {

  var this__ = this;

  this__.gravitation = 1;

  this.init = function(data) {
    this__.rawData = data;
    this__.gameLoopTime = 50;
    this__.subjects = {};
    this__.container = $('div.spam-zone');
    this__.timer = 0;
    this__.accelerate = 30 * 1000;
    this__.gameInterval = null;
    this__.accelerateInterval = null;
    this__.numberOfSubjects = 0;
    this__.pause = false;

    // Randomize subjects sequences
    var randomSubjects = this__.shuffle();

    $.each(randomSubjects, function(i_, v_){
      $.each(v_, function(index, value){
        var subject = new Subject(value);
        var domObject = $('<div data-points="' + subject.points + '" class="element"><span class="' + subject.cssClassName + '"></span></div>');
        if(subject.effect == 'bad') {
          domObject.addClass('bad-guy');
        }

        var max = 10, min = -10;
        if (subject.angle == 0) {
          subject.angle =  parseInt(Math.floor(Math.random() * (max - min + 1)) + min);
        }

        domObject.css({
            'transform': 'translate(' + subject.position.left + 'px, ' + subject.position.top + 'px) rotate(' + subject.angle + 'deg)'
        });

        subject.RefToDOM = domObject;
        subject.id = index;
        this__.subjects[index] = subject;
        this__.numberOfSubjects = index + 1;
          // this__.container.append(domObject);
      });    
    });
  };

  this.shuffle = function() {
    var j, x, i, counter = -1, shuffled = [], 
        arr = this__.rawData;

    $.each(arr, function(i, v){
      if (counter == -1 || v.title == "separator") {
        counter++;
        shuffled[counter] = [ ];
      }
      shuffled[counter].push(v);
    });

    for (i = shuffled.length; i; i--) {
        j = Math.floor(Math.random() * i);
        x = shuffled[i - 1];
        shuffled[i - 1] = shuffled[j];
        shuffled[j] = x;
    }

    // return shuffled.slice(0,3);
    return shuffled;
  };

  this.start = function() {
    console.log(this__);

    this__.gameInterval = setInterval(function(){
        // Calculate game time
      this__.timer = this__.timer + this__.gameLoopTime;

      $.each(this__.subjects, function(index, obj){
        // Append new object
        if ((this__.timer > obj.delay) && (obj.isOnBoard == false)) {
          obj.addToScene();
        }
        // Move objects on scene (board)
        if (obj.isOnBoard == true) {
          // obj.move(); // --andrewgs Replaced with keyframe animation
          obj.currentPeriod = obj.currentPeriod + this__.gameLoopTime;
        }
        // Remove object
        if ((obj.currentPeriod > obj.alivePeriod) && (obj.isOnBoard == true)) {
          obj.removeFromScene();
        }
      });
    }, this__.gameLoopTime / this__.gravitation); // Main game loop

    // Accelerate the game. Aka hardcore mode.
    // this.accelerateInterval = setInterval(function(){
    //   console.log(this__)
    //   this__.gravitation = this__.gravitation * 1.2;
    //   if (this__.gravitation > 3) {
    //     game.finish();
    //   }
    // }, this__.accelerate);
  };

  this.pause = function() {
      this__.pause = true;
  };

  this.finish = function() {
    clearInterval(game.gameInterval);
    clearInterval(game.accelerateInterval);
    game.container.empty();

    vmetro.gameStats.lifes = false;
    vmetro.gameStats.onAir = false;
    vmetro.gameStats.score = parseInt($('.hud .score-counter').text());

    var key = vmetro.manual.encryption(vmetro.gameStats.userId + vmetro.gameStats.score);
    $.ajax({
      type: 'POST',
      dataType: 'json',
      data: {
        'name' : vmetro.gameStats.userId,
        'score': vmetro.gameStats.score,
        'key': key
      },
      url: '/csv_connect.php'
    }).done(function(response) {
      if(response) {
        vmetro.manual.parseResult(response);
      }
      ga("send", "event", "game", "result table shown");
    }).fail(function (response) {
      // $('.bad-result .leader-table').html(leaderBoard.join(''));
      $('.bad-result .js-your-result').text(vmetro.gameStats.score);
      $('.bad-result').addClass('active');
    });
  },

  this.restart = function() {
    // this__.gravitation = 1;
    var game = new Game();
    this__.init();
    this__.start();
  };
};

var game = new Game();

vmetro.game = {

  animation: function (element) {
    var points = element.attr('data-points'),
        splash = '',
        redSpot = $('<div class="red-spot"><span></span></div>'),
        blueSpot = $('<div class="blue-spot">\
                      <span>+' + points + '</span>\
                    </div>');

    if (element.parent().hasClass('hints')) {
      if (element.hasClass('bad-guy')) {
        splash = redSpot;
      } else {
        splash = blueSpot;
      }
    } else {
      if (element.hasClass('element') && !element.hasClass('bad-guy')) {
        splash = blueSpot;
        $('.hud .score-counter').addClass('shake');
        $('.hud .score-counter').html(parseInt(points) + parseInt($('.hud .score-counter').text()));
        setTimeout(function () {
          $('.hud .score-counter').removeClass('shake');
        }, 500);
      } else if (element.hasClass('bad-guy') && element.hasClass('element')) {
        splash = redSpot;
        // vmetro.gameStats.onAir = false;
        //
        // setTimeout(function () {
        //   vmetro.game.relaunchAnimation();
        // }, 600);

        $('.lifes .alive:last').removeClass('alive');
        if($('.lifes .alive').length == 0) {
          vmetro.gameStats.lifes = false;
          vmetro.gameStats.onAir = false;
          vmetro.gameStats.score = parseInt($('.hud .score-counter').text());
          game.finish();
        }
      }
    }

    element.append(splash);
    splash.parent().addClass('deactivated');
    setTimeout(function () {
      if (splash) {
        splash.parent().fadeOut(300);
        // setTimeout(function () {
        //   splash.fadeOut(300);
        // }, 300);
      }
    }, 600);
  },

  generateId: function () {
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
        date = new Date(),
        yyyy = date.getFullYear().toString(),
        mm = (date.getMonth()+1).toString(),
        dd  = date.getDate().toString(),
        mmChars = mm.split(''),
        ddChars = dd.split(''),
        curentDate = yyyy + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0]);

    vmetro.gameStats.userId += curentDate + '-';

    for(var i = 0; i < 10; i++) {
      vmetro.gameStats.userId += possible.charAt(Math.floor(Math.random() * possible.length));
    }
  },

  // over: function () {
    // vmetro.gameStats.interval = 1 * 1000;
    // vmetro.gameStats.intervalPixels =  1 * 1000;
  // },

  sharing: function(target) {
    var network = target.attr('data-network'),
        networkUrl = target.attr('href'),
        pageUrl = window.location.href,
        url = '';

    if(network == 'vk') {
      url = networkUrl + pageUrl + '/';
    } else if (network == 'fb') {
      url = networkUrl + pageUrl;
    } else {
      return;
    };

    window.open(url,'','toolbar=0,status=0,width=626,height=436');
  },

  init: function () {
    var t = this;
    // t.generateId();
    $(document).on('click tap', '.element', function () {
      t.animation($(this));
    });

    $('.socials-row a').on('click tap', function() {
      t.sharing($(this));
    });

    $(document).on('click tap', 'form .start-button', function (e) {
      e.preventDefault();
      $('.player-name').text(vmetro.gameStats.userId);
      $('.manual').addClass('started');
      $('form').hide();

      var animateBG = function() {
        $('.game-screen').animate({'background-position-y': '-10000'}, 40000, function () {
          animateBG();
        });
      }
      animateBG();

      // vmetro.gameStats.onAir = true;
      setTimeout(function () {
        $('.manual').remove();
        game.init(data);
        game.start();
      }, 500);
    });

    $('.bad-result .try-again, .good-result .try-again').on('click tap', function () {

      // setTimeout(function() {
      //   vmetro.gameStats.lifes = false;
      //   vmetro.gameStats.onAir = false;
      //   vmetro.gameStats.score = parseInt($('.hud .score-counter').text());
      //   vmetro.game.over();
      // }, vmetro.gameStats.intervalSpeed * 1000 * 3);

      $.each($('.lifes li'), function () {
        $(this).addClass('alive');
      });
      $('.score-counter').text(0);
      $('.bad-result, .good-result').removeClass('active')

      // Destroy previous game and start new
      // game.restart();
      game = null;
      game = new Game();
      game.init(data);
      game.start();
    });
  }
};

vmetro.manual = {

  setActiveSlide: function(index) {
    var slideToShow = $('.manual-screen[data-slide-index="' + index + '"]');
        bullet = $('.manual-nav li[data-slide-index="' + index + '"]');

    bullet.siblings().removeClass('curent-screen-bullet');
    bullet.addClass('curent-screen-bullet');

    slideToShow.siblings().removeClass('curent-screen');
    slideToShow.addClass('curent-screen');
  },

  detectSlideDir: function(e) {
    var startPos = e.originalEvent.touches[0].pageX,
        lastPos = e.originalEvent.touches[0].pageX,
        countSwipe = true;

    $(document).on('touchmove', function(e){
      if(countSwipe) {
        lastPos = e.originalEvent.touches[0].pageX;
      }
    });

    $(document).on('touchend', function(e){
      if(countSwipe && Math.abs(startPos - lastPos) > 50) {
        if(startPos > lastPos) {
          // $('.manual').trigger('swipe::left');
          vmetro.manual.sliderSwipe('right');
        } else {
          // $('.manual').trigger('swipe::right');
          vmetro.manual.sliderSwipe('left');
        }
      }
      countSwipe = false;
    });
  },

  sliderSwipe: function(swipeDir) {
    var slider = $('.manual'),
        slidesCounter = slider.find('.manual-screen').length,
        curentIndex = parseInt($('.curent-screen-bullet').attr('data-slide-index'));

    if(swipeDir == 'left') {
      var SwipeNextIndex = curentIndex - 1;
      if(SwipeNextIndex <= 0) {
        SwipeNextIndex = slidesCounter;
      }
      vmetro.manual.setActiveSlide(SwipeNextIndex);

    } else if(swipeDir == 'right') {
      var SwipeNextIndex = curentIndex + 1;
      if(SwipeNextIndex > slidesCounter) {
        SwipeNextIndex = 1;
      }
      vmetro.manual.setActiveSlide(SwipeNextIndex);
    }
  },

  microValidation: function (input) {

    if(input.val() !== undefined && input.val() !== '') {
      $('button').addClass('active');
      vmetro.gameStats.userId = input.val();
    } else {
      $('button').removeClass('active');
    };
  },

  parseResult: function(data) {
    var leaderBoard = [],
        highScore = 0,
        playersPlace = 0;
        // dictionary = ['первое','второе','третье','четвёртое','пятое','шестое','седьмое','восьмое','девятое','десятое'];
        dictionary = ['первое','второе','третье','четвёртое','пятое'];

    $.each(data, function (i,v) {
      ind = i+1;
      if(v.current > 0) {
        highScore = 1;
        playersPlace = i;
        leaderBoard.push('<div class="leader-row leader">');
      } else {
        leaderBoard.push('<div class="leader-row">');
      }
      leaderBoard.push('<div class="leader-nubmer">' + ind + '.</div>\
                        <div class="leader-name">' + v.name + '</div>\
                        <div class="leader-score">' + v.score + '</div>\
                      </div>');
    });

    if(highScore > 0) {
      $('.js-string-place').text(dictionary[playersPlace]);
      $('.good-result .leader-table').html(leaderBoard.join(''));
      $('.good-result .js-your-result').text(vmetro.gameStats.score);
      $('.good-result').addClass('active');
    } else {
      $('.bad-result .leader-table').html(leaderBoard.join(''));
      $('.bad-result .js-your-result').text(vmetro.gameStats.score);
      $('.bad-result').addClass('active');
      // $('.js-your-result').text(vmetro.gameStats.score);
      // $('.js-need-result').text(highScore - vmetro.gameStats.score);
    }
  },

  encryption: function (string) {
    function RotateLeft(lValue, iShiftBits) {
      return (lValue<<iShiftBits) | (lValue>>>(32-iShiftBits));
    }

    function AddUnsigned(lX,lY) {
      var lX4,lY4,lX8,lY8,lResult;
      lX8 = (lX & 0x80000000);
      lY8 = (lY & 0x80000000);
      lX4 = (lX & 0x40000000);
      lY4 = (lY & 0x40000000);
      lResult = (lX & 0x3FFFFFFF)+(lY & 0x3FFFFFFF);
      if (lX4 & lY4) {
             return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
      }
      if (lX4 | lY4) {
        if (lResult & 0x40000000) {
          return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
        } else {
          return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
        }
      } else {
        return (lResult ^ lX8 ^ lY8);
      }
    }

    function F(x,y,z) { return (x & y) | ((~x) & z); }
    function G(x,y,z) { return (x & z) | (y & (~z)); }
    function H(x,y,z) { return (x ^ y ^ z); }
    function I(x,y,z) { return (y ^ (x | (~z))); }

    function FF(a,b,c,d,x,s,ac) {
      a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
      return AddUnsigned(RotateLeft(a, s), b);
    };

    function GG(a,b,c,d,x,s,ac) {
      a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
      return AddUnsigned(RotateLeft(a, s), b);
    };

    function HH(a,b,c,d,x,s,ac) {
      a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
      return AddUnsigned(RotateLeft(a, s), b);
    };

    function II(a,b,c,d,x,s,ac) {
      a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
      return AddUnsigned(RotateLeft(a, s), b);
    };

    function ConvertToWordArray(string) {
      var lWordCount;
      var lMessageLength = string.length;
      var lNumberOfWords_temp1=lMessageLength + 8;
      var lNumberOfWords_temp2=(lNumberOfWords_temp1-(lNumberOfWords_temp1 % 64))/64;
      var lNumberOfWords = (lNumberOfWords_temp2+1)*16;
      var lWordArray=Array(lNumberOfWords-1);
      var lBytePosition = 0;
      var lByteCount = 0;
      while ( lByteCount < lMessageLength ) {
        lWordCount = (lByteCount-(lByteCount % 4))/4;
        lBytePosition = (lByteCount % 4)*8;
        lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount)<<lBytePosition));
        lByteCount++;
      }
      lWordCount = (lByteCount-(lByteCount % 4))/4;
      lBytePosition = (lByteCount % 4)*8;
      lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80<<lBytePosition);
      lWordArray[lNumberOfWords-2] = lMessageLength<<3;
      lWordArray[lNumberOfWords-1] = lMessageLength>>>29;
      return lWordArray;
    };

    function WordToHex(lValue) {
      var WordToHexValue="",WordToHexValue_temp="",lByte,lCount;
      for (lCount = 0;lCount<=3;lCount++) {
        lByte = (lValue>>>(lCount*8)) & 255;
        WordToHexValue_temp = "0" + lByte.toString(16);
        WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length-2,2);
     }
     return WordToHexValue;
    };

    function Utf8Encode(string) {
      string = string.replace(/\r\n/g,"\n");
      var utftext = "";
      for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);
        if (c < 128) {
          utftext += String.fromCharCode(c);
        }
        else if((c > 127) && (c < 2048)) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        }
        else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          tftext += String.fromCharCode((c & 63) | 128);
        }

      }
      return utftext;
    };

    var x=Array();
    var k,AA,BB,CC,DD,a,b,c,d;
    var S11=7, S12=12, S13=17, S14=22;
    var S21=5, S22=9 , S23=14, S24=20;
    var S31=4, S32=11, S33=16, S34=23;
    var S41=6, S42=10, S43=15, S44=21;

    string = Utf8Encode(string);

    x = ConvertToWordArray(string);

    a = 0x67452301; b = 0xEFCDAB89; c = 0x98BADCFE; d = 0x10325476;

    for (k=0;k<x.length;k+=16) {
      AA=a; BB=b; CC=c; DD=d;
      a=FF(a,b,c,d,x[k+0], S11,0xD76AA478);
      d=FF(d,a,b,c,x[k+1], S12,0xE8C7B756);
      c=FF(c,d,a,b,x[k+2], S13,0x242070DB);
      b=FF(b,c,d,a,x[k+3], S14,0xC1BDCEEE);
      a=FF(a,b,c,d,x[k+4], S11,0xF57C0FAF);
      d=FF(d,a,b,c,x[k+5], S12,0x4787C62A);
      c=FF(c,d,a,b,x[k+6], S13,0xA8304613);
      b=FF(b,c,d,a,x[k+7], S14,0xFD469501);
      a=FF(a,b,c,d,x[k+8], S11,0x698098D8);
      d=FF(d,a,b,c,x[k+9], S12,0x8B44F7AF);
      c=FF(c,d,a,b,x[k+10],S13,0xFFFF5BB1);
      b=FF(b,c,d,a,x[k+11],S14,0x895CD7BE);
      a=FF(a,b,c,d,x[k+12],S11,0x6B901122);
      d=FF(d,a,b,c,x[k+13],S12,0xFD987193);
      c=FF(c,d,a,b,x[k+14],S13,0xA679438E);
      b=FF(b,c,d,a,x[k+15],S14,0x49B40821);
      a=GG(a,b,c,d,x[k+1], S21,0xF61E2562);
      d=GG(d,a,b,c,x[k+6], S22,0xC040B340);
      c=GG(c,d,a,b,x[k+11],S23,0x265E5A51);
      b=GG(b,c,d,a,x[k+0], S24,0xE9B6C7AA);
      a=GG(a,b,c,d,x[k+5], S21,0xD62F105D);
      d=GG(d,a,b,c,x[k+10],S22,0x2441453);
      c=GG(c,d,a,b,x[k+15],S23,0xD8A1E681);
      b=GG(b,c,d,a,x[k+4], S24,0xE7D3FBC8);
      a=GG(a,b,c,d,x[k+9], S21,0x21E1CDE6);
      d=GG(d,a,b,c,x[k+14],S22,0xC33707D6);
      c=GG(c,d,a,b,x[k+3], S23,0xF4D50D87);
      b=GG(b,c,d,a,x[k+8], S24,0x455A14ED);
      a=GG(a,b,c,d,x[k+13],S21,0xA9E3E905);
      d=GG(d,a,b,c,x[k+2], S22,0xFCEFA3F8);
      c=GG(c,d,a,b,x[k+7], S23,0x676F02D9);
      b=GG(b,c,d,a,x[k+12],S24,0x8D2A4C8A);
      a=HH(a,b,c,d,x[k+5], S31,0xFFFA3942);
      d=HH(d,a,b,c,x[k+8], S32,0x8771F681);
      c=HH(c,d,a,b,x[k+11],S33,0x6D9D6122);
      b=HH(b,c,d,a,x[k+14],S34,0xFDE5380C);
      a=HH(a,b,c,d,x[k+1], S31,0xA4BEEA44);
      d=HH(d,a,b,c,x[k+4], S32,0x4BDECFA9);
      c=HH(c,d,a,b,x[k+7], S33,0xF6BB4B60);
      b=HH(b,c,d,a,x[k+10],S34,0xBEBFBC70);
      a=HH(a,b,c,d,x[k+13],S31,0x289B7EC6);
      d=HH(d,a,b,c,x[k+0], S32,0xEAA127FA);
      c=HH(c,d,a,b,x[k+3], S33,0xD4EF3085);
      b=HH(b,c,d,a,x[k+6], S34,0x4881D05);
      a=HH(a,b,c,d,x[k+9], S31,0xD9D4D039);
      d=HH(d,a,b,c,x[k+12],S32,0xE6DB99E5);
      c=HH(c,d,a,b,x[k+15],S33,0x1FA27CF8);
      b=HH(b,c,d,a,x[k+2], S34,0xC4AC5665);
      a=II(a,b,c,d,x[k+0], S41,0xF4292244);
      d=II(d,a,b,c,x[k+7], S42,0x432AFF97);
      c=II(c,d,a,b,x[k+14],S43,0xAB9423A7);
      b=II(b,c,d,a,x[k+5], S44,0xFC93A039);
      a=II(a,b,c,d,x[k+12],S41,0x655B59C3);
      d=II(d,a,b,c,x[k+3], S42,0x8F0CCC92);
      c=II(c,d,a,b,x[k+10],S43,0xFFEFF47D);
      b=II(b,c,d,a,x[k+1], S44,0x85845DD1);
      a=II(a,b,c,d,x[k+8], S41,0x6FA87E4F);
      d=II(d,a,b,c,x[k+15],S42,0xFE2CE6E0);
      c=II(c,d,a,b,x[k+6], S43,0xA3014314);
      b=II(b,c,d,a,x[k+13],S44,0x4E0811A1);
      a=II(a,b,c,d,x[k+4], S41,0xF7537E82);
      d=II(d,a,b,c,x[k+11],S42,0xBD3AF235);
      c=II(c,d,a,b,x[k+2], S43,0x2AD7D2BB);
      b=II(b,c,d,a,x[k+9], S44,0xEB86D391);
      a=AddUnsigned(a,AA);
      b=AddUnsigned(b,BB);
      c=AddUnsigned(c,CC);
      d=AddUnsigned(d,DD);
    }

    var temp = WordToHex(a)+WordToHex(b)+WordToHex(c)+WordToHex(d);

    return temp.toLowerCase();
  },

  init: function () {
    var t = this;

    $(document).on('keyup', '#input', function () {
      if (this.value.length >= 15) {
        this.value = this.value.slice(0,15);
      }
      t.microValidation($(this));
    });

    $('.manual').fotorama({
      width: "100%",
      height: vmetro.gameStats.windowHeight,
      // loop: true,
      arrows: false,
      click: false,
      swipe: true,

      onClick: function(data) {
        $('#input').focus();
      }
    });

    $('.manual').on('fotorama:showend', function (e, fotorama) {
      if(fotorama.activeIndex == 1) {
        $('.screen-2 .red-spot').show();
      } else if(fotorama.activeIndex == 2) {
        $('form').fadeIn(300);
      }
    });

    $('.manual').on('fotorama:show', function (e, fotorama) {
      $('form').hide();
    });
  }
};

$(document).on('ready', function () {
  vmetro.manual.init();
  vmetro.game.init();
});

// Prevent double tap on iOS devices
(function($) {
  var IS_IOS = /iphone|ipad/i.test(navigator.userAgent);
  $.fn.nodoubletapzoom = function() {
    if (IS_IOS)
      $(this).bind('touchstart', function preventZoom(e) {
        var t2 = e.timeStamp
          , t1 = $(this).data('lastTouch') || t2
          , dt = t2 - t1
          , fingers = e.originalEvent.touches.length;
        $(this).data('lastTouch', t2);
        if (!dt || dt > 500 || fingers > 1) return; // not double-tap

        e.preventDefault(); // double tap - prevent the zoom
        // also synthesize click events we just swallowed up
        $(this).trigger('click').trigger('click');
      });
  };
})(jQuery);


